import React from 'react';
import {Image, RichText, Text, Link, isExperienceEditorActive} from '@sitecore-jss/sitecore-jss-react';
import ScrollAnimation from 'react-animate-on-scroll';

const ContentItem = ({fields, openModal}) => {
	let linkField;
	if (fields.videoLinkText.value.length !== 0) {
		linkField = (
			<button type="button" className="video-modal-cta content-link" onClick={() => openModal()}>
				<Text field={fields.videoLinkText} />
			</button>
		);
	} else if (fields.link.value.text !== undefined) {
		linkField = <Link className="content-link" field={fields.link} />;
	} else {
		linkField = <span className="arrow" />;
	}

	const content = (
		<React.Fragment>
			{fields.title.value && <Text className ={!isExperienceEditorActive() && fields.titleImage.value && fields.titleImage.value.src ? 'vh' : ''} field={fields.title} data-amid={fields.title.id} tag="h1" />}
			{fields.titleImage.value && (
				<Image className="title-image" field={fields.titleImage} data-amid={fields.titleImage.id} />
			)}
			{fields.description.value && (
				<RichText field={fields.description} data-amid={fields.description.id} />
			)}
			{linkField}
		</React.Fragment>
	);

	return (
		<div className={`content ${!fields.image.value ? 'no-image' : ''}`}>
			<span className="content-grouper">
				{fields.hasFade.value && fields.isFullWidthImage.value ? (
					<React.Fragment>
						<ScrollAnimation
							animateIn="fadeInDown"
							animateOnce
							delay={100}
							duration={0.5}
							className="header-highlight"
						/>
						<ScrollAnimation animateIn="fadeInDown" animateOnce delay={350} duration={0.5}>
							{content}
						</ScrollAnimation>
					</React.Fragment>
				) : (
					<React.Fragment>
						<span className="header-highlight" />
						{content}
					</React.Fragment>
				)}
			</span>
		</div>
	);
};

export default ContentItem;
