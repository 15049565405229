import SingleLineText from '../SingleLineText';

const mock = [
	{
		fields: {
			title: SingleLineText.mock,
		},
		id: '',
	},
	{
		fields: {
			title: SingleLineText.mock,
		},
		id: '',
	},
];

export default mock;
