import PropTypes from 'prop-types';

const propTypes = {
	default: PropTypes.arrayOf(PropTypes.any),
	inject: itemPropTypes => {
		return PropTypes.arrayOf(
			{
				id: PropTypes.string,
				fields: itemPropTypes,
			},
		);
	},
};

export default propTypes;
