const mock = {
	value: {
		src: 'http://via.placeholder.com/150',
		alt: 'this is the alt tag',
		width: '',
		height: '',
	},
};

export default mock;
